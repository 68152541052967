import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import TodayFrontpages from './components/TodayFrontpages';
import LatestArticles from './components/LatestArticles';
import Home from './components/Home';
import FrontpagesSelector from './components/FrontpagesSelector';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import StatisticsHome from './components/StatisticsHome';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Oxygen',
      'sans-serif'
    ].join(','),
    textTransform: 'none',
    fontSize: 16,
  },
});

const titles = {
  '/': 'Portadas CR',
  '/media': 'Historial',
  '/today/frontpages':'Portadas',
  '/media/articles/latest':'Últimas noticias',
  '/media/stats':'Datos'
}

const App = () => {
  const [currentTheme] = useState(theme);
  const location = useLocation()
  
  useEffect(() => {
    updatePageTitle();
  }, [location]);

  const updatePageTitle = () => {
    document.title = titles[location.pathname] || 'PortadasCR';
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/today/frontpages" element={<TodayFrontpages />} />
        <Route exact path="/media" element={<FrontpagesSelector />} />
        <Route exact path="/media/articles/latest" element={<LatestArticles />} />
        <Route exact path="/media/stats" element={<StatisticsHome />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
