import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';



class StatsGrid extends React.Component {

  render() {
    const { frontpage, selectedMedia } = this.props;
    const frontpages = Array.isArray(frontpage) ? frontpage : [frontpage];

    const filteredFrontpage = selectedMedia
      ? frontpages.filter((fp) => fp.media_name === selectedMedia)
      : frontpages;

    return (
      <div className="media-frontpage">
        {filteredFrontpage.map((fp) => (
            <Container key={fp.media_name} sx={{ py: 4, backgroundColor: '#F9F9F9', borderRadius: '10px' }} maxWidth="lg">
              <Typography gutterBottom variant="h4" component="h2" sx={{ '& a': { color: 'black', textDecoration: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }, '&:hover': {backgroundColor: '#F0F0F0'} }}>
                <a href= {fp.url} target="_blank" rel="noopener noreferrer">
                {fp.media_name}
                </a>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  component="p"
                >
                  {fp.url}
                </Typography>
              </Typography>
              <hr className="divider" />
            {frontpages.map((frontpage) => (
              <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" rowSpacing={4} spacing={4}>
                    <Grid item key="article-count-card" xs={12} sm={6} md={4} lg={4}>
                      <div className="article">
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                          <Typography gutterBottom variant="h6" component="h2" sx={{ '& a': { color: 'black', textDecoration: 'none' }, '&:hover': {backgroundColor: '#F0F0F0'} }}>
                            
                                Cantidad de articulos
                            </Typography>
                          
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography variant="body-2" display="block">
                             {fp.article_count}
                            </Typography>
                          </CardContent>
                          <CardActions>
                          </CardActions>
                        </Card>
                      </div>
                    </Grid>
                    <Grid item key="article-count-card" xs={12} sm={6} md={4} lg={4}>
                      <div className="article">
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                          <Typography gutterBottom variant="h6" component="h2" sx={{ '& a': { color: 'black', textDecoration: 'none' }, '&:hover': {backgroundColor: '#F0F0F0'} }}>
                            
                                Temas
                            </Typography>
                          
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography variant="body-2" display="block">
                              {fp.topics.map(
                                (topic) => (
                                  <Button variant="outlined" size="small">{topic}</Button>
                                )
                              )}
                            </Typography>
                          </CardContent>
                          <CardActions>
                          </CardActions>
                        </Card>
                      </div>
                    </Grid>
                    <Grid item key="article-count-card" xs={12} sm={6} md={4} lg={4}>
                      <div className="article">
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                          <Typography gutterBottom variant="h6" component="h2" sx={{ '& a': { color: 'black', textDecoration: 'none' }, '&:hover': {backgroundColor: '#F0F0F0'} }}>
                            
                                Cantidad de articulos por autor
                            </Typography>
                            
                          <CardContent sx={{ flexGrow: 1 }}>
                          {fp.author_count.map(
                                (author) => (
                                  <Typography variant="body-2" display="block"><Button size="small" >{author.author} </Button>: {author.article_count}</Typography>
                                )
                              )}
                              
                          </CardContent>
                          <CardActions>
                          </CardActions>
                        </Card>
                      </div>
                    </Grid>
                  </Grid>
              ))}
            </Container>
            
        ))}
      </div>
    );
  }
}

export default StatsGrid;




