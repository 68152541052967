import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { Grid, Box, Card, CardMedia, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import ResponsiveAppBar from './ResponsiveAppBar';
import TelegramIcon from '@mui/icons-material/Telegram';
import Copyright from './Copyright';
import axios from 'axios';

// Create a new Axios instance with custom configuration
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const credentials = { username: process.env.REACT_APP_PORTADASCR_API_USER, password: process.env.REACT_APP_PORTADASCR_API_PASS};

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      medias: {},
      promoItems: [
        {
          id: 1,
          image: '/news-tv.webp',
          text: 'Últimas noticias',
          link: 'media/articles/latest',
        },
        {
          id: 2,
          image: '/news-shop.webp',
          text: 'Portadas del día',
          link: '/today/frontpages',
        },
        {
          id: 3,
          image: '/news-timeline.webp',
          text: 'Historial de portadas',
          link: '/media',
        },
        {
          id: 4,
          image: '/news-stats.webp',
          text: 'Datos de los medios',
          link: '/media/stats',
        },
        // Add more promo items as needed
      ],
    };
  }

  fetchAccessToken = () => {

    return instance
      .post('/login', credentials)
      .then((response) => {
        // Store the access token in the component's state
        this.setState({ accessToken: response.data.access_token });
        return response.data.access_token 
      })
      .catch((error) => {
        console.error(error);
      });
  };

  componentDidMount() {
    this.fetchAccessToken().then( (token) => {
      //console.log('Token: ', token);
    instance
      .get('/media/topics/wordcloud', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.setState({ medias: response.data });
        //console.log('data: ', response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    }
    );
  }

  render() {
    const { medias,promoItems } = this.state;

    // Determine the screen size and select the appropriate word cloud
    let wordcloudHtml = null;
    if (window.innerWidth >= 1100) {
      wordcloudHtml = medias.wordcloud;
    } else if (window.innerWidth >= 768) {
      wordcloudHtml = medias.wordcloud_md;
    } else {
      wordcloudHtml = medias.wordcloud_sm;
    }

    return (
      <div>
        <ResponsiveAppBar />
        <main>
          {/* Hero unit */}
          <Box
            sx={{
              bgcolor: 'background.paper',
              pt: 8,
              pb: 6,
            }}
          >
            <Container maxWidth="sm">
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="text.primary"
                gutterBottom
              >
                Portadas CR
              </Typography>
              <Typography variant="h5" align="center" color="text.secondary" paragraph>
                Recopilación de las portadas de diferentes sitios web de noticias de Costa Rica.
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                <Link href="https://t.me/portadas_cr" underline="none" target="_blank" rel="noopener noreferrer">
                  <Button variant="contained" endIcon={<TelegramIcon />}>
                    Canal de Telegram
                  </Button>
                </Link>
                <Typography variant="caption" align="center">
                  Recibe todas las noticias en nuestro canal de Telegram
                </Typography>
              </Box>
              <Box sx={{ padding: '3rem' }}>
                <Grid container spacing={3}>
                  {promoItems.map((item) => (
                    <Grid item xs={12} sm={6} md={4} lg={6} key={item.id}>
                      <Card className="promo-item" sx={{ position: 'relative' }}>
                        <CardMedia component="img" image={item.image} alt={item.text} height="200" />
                        <Typography
                          component="a"
                          href={item.link}
                          variant="h6"
                          align="center"
                          className="promo-text"
                          sx={{
                            zIndex: 1,
                            textDecoration: 'none',
                            fontWeight: 'bold', // Make the typography bold
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Add a shadow to the typography
                          }}
                        >
                          <CardContent sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            {item.text}
                          </CardContent>
                        </Typography>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box>
             
              </Box>
            </Container>             
            <Container maxWidth="lg" sx={{ padding: '2rem' }}>
              <Box>
              <Typography variant="h7" align="center" gutterBottom>
                ¡Bienvenido a Portadas_CR! Aquí encontrarás una recopilación de los artículos destacados de los principales sitios web de noticias de Costa Rica. Nuestro objetivo es mantenerte informado sobre los temas más relevantes de actualidad en el país y comparar las diferentes perspectivas entre los medios. Explora nuestras secciones y descubre las portadas a lo largo del tiempo para cada medio. Siempre estamos actualizando nuestra selección para brindarte una visión completa de lo que está sucediendo en Costa Rica. ¡Gracias por visitarnos!
              </Typography>
              </Box>
              <Box  className="svg-container" sx={{ padding: '2rem' }}>
                <div
                  className="svg-content"
                  dangerouslySetInnerHTML={{ __html: wordcloudHtml }}
                />
              </Box>
              <Typography
                  variant="subtitle2"
                  align="center"
                  color="text.secondary"
                  component="p"
                >
                  Nube de palabras general. Incluye los temas principales de todos los articulos de cada medio hasta la fecha.
                  </Typography> 
            </Container>
          </Box>
        </main>
        {/* Footer */}
        <hr className="divider" />
        <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
          <Typography
            variant="subtitle2"
            align="center"
            color="text.secondary"
            component="p"
          >
            Portadas_CR es un sitio web que recopila y muestra las portadas de diversos sitios web de noticias de Costa Rica. No somos responsables del contenido de los artículos, ni garantizamos la exactitud, integridad o actualidad de la información presentada. Los enlaces a los artículos te redirigirán directamente al sitio web original de la noticia, donde podrás acceder al contenido completo. Ten en cuenta que los artículos pueden estar sujetos a derechos de autor y otras restricciones. Utiliza la información a tu propio riesgo y verifica la veracidad de los datos. Si tienes alguna pregunta o inquietud, no dudes en contactarnos. ¡Gracias por utilizar Portadas_CR!
          </Typography>
          <Copyright />
        </Box>
        {/* End footer */}
      </div>
    );
  }
}

export default Home;
