import React, { Component } from 'react';
import { Box,Button,Grid} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from 'axios';
import { format } from 'date-fns';
import ResponsiveAppBar from './ResponsiveAppBar';
import MediaGrid from './MediaGrid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

// Create a new Axios instance with custom configuration
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const credentials = { username: process.env.REACT_APP_PORTADASCR_API_USER, password: process.env.REACT_APP_PORTADASCR_API_PASS};

class FrontPageSelector extends Component {
  constructor(props) {
    super(props);
    const today = new Date(); // Get today's date
    this.state = {
      mediaNames: [],
      selectedMedia: '',
      selectedDate: today,
      selectedDateString: format(today, 'yyyy-MM-dd'),
      frontpage: [],
    };
  }

  handleDateChange = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    this.setState({ selectedDate: date, selectedDateString: formattedDate }, () => {
      this.fetchFrontpage();
    });
  };

  handlePrevDay = () => {
    const { selectedDate } = this.state;
    const prevDay = new Date(selectedDate);
    prevDay.setDate(selectedDate.getDate() - 1);
    const formattedDate = format(prevDay, 'yyyy-MM-dd');
    this.setState({ selectedDate: prevDay, selectedDateString: formattedDate }, () => {
      this.fetchFrontpage();
    });
  };

  handleNextDay = () => {
    const { selectedDate } = this.state;
    const nextDay = new Date(selectedDate);
    nextDay.setDate(selectedDate.getDate() + 1);
    const formattedDate = format(nextDay, 'yyyy-MM-dd');
    this.setState({ selectedDate: nextDay, selectedDateString: formattedDate }, () => {
      this.fetchFrontpage();
    });
  };

  handleMediaChange = (event) => {
    this.setState({ selectedMedia: event.target.value }, () => {
        this.fetchFrontpage();
      });
  };

  fetchAccessToken = () => {

    return instance
      .post('/login', credentials)
      .then((response) => {
        // Store the access token in the component's state
        this.setState({ accessToken: response.data.access_token });
        return response.data.access_token 
      })
      .catch((error) => {
        console.error(error);
      });
  };

  fetchFrontpage = () => {
    this.fetchAccessToken().then((token) => {
      instance
        .get('/media/' + this.state.selectedMedia + '/' + this.state.selectedDateString + '/frontpage', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.setState({ frontpage: response.data });
          //console.log('data: ', response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            // If the API returns a 404 status (Not Found), set frontpage to null
            this.setState({ frontpage: [] });
          } else {
            console.error(error);
          }
        });
    });
  };

  componentDidMount() {

    this.fetchAccessToken().then( (token) => {
      //console.log('Token: ', token);
    instance
      .get('/media/names', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.setState({ mediaNames: response.data });
        //console.log('data: ', response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    }
    );
  }

  

  render() {
    const { selectedMedia, selectedDate, mediaNames, frontpage } = this.state;
    
    

    return (
      <div>
        <ResponsiveAppBar toggleDrawer={this.toggleDrawer} />
        <Typography
                component="h1"
                variant="h3"
                align="center"
                color="text.primary"
                gutterBottom
              >
                Historial de portadas
              </Typography>   
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            

            <FormControl sx={{ m: 2, minWidth: 80 }}>
                <InputLabel id="media_name">Medio</InputLabel>
                <Select
                labelId="media_name"
                id="media_name-select-autowidth"
                value={selectedMedia}
                onChange={this.handleMediaChange}
                autoWidth
                label="Medio"
                >
                {mediaNames.map((mediaName) => (
                    <MenuItem
                    key={mediaName}
                    value={mediaName}
                    >
                    {mediaName}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>

        </Box>


        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container justifyContent="center" alignItems="center">
              <Button onClick={this.handlePrevDay}>
                <ArrowBackIcon />
              </Button>
              <DatePicker
                value={selectedDate}
                onChange={this.handleDateChange}
                renderInput={(params) => <input {...params} />}
              />
              <Button onClick={this.handleNextDay}>
                <ArrowForwardIcon />
              </Button>
            </Grid>
          </LocalizationProvider>
        </Box>

        <Box sx={{ mt: 2}}>            
        <MediaGrid
          key={frontpage.media_name}
          frontpage={frontpage}
          selectedMedia=""
        />
        </Box>
      </div>
    );
  }
}

export default FrontPageSelector;
