import * as React from 'react';
import ResponsiveAppBar from './ResponsiveAppBar';
import axios from 'axios';
import MediaGrid from './MediaGrid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Create a new Axios instance with custom configuration
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const credentials = { username: process.env.REACT_APP_PORTADASCR_API_USER, password: process.env.REACT_APP_PORTADASCR_API_PASS};

class TodayFrontpages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      frontpages: [],
      selectedMedia: '',
      isDrawerOpen: false,
    };
  }

  fetchAccessToken = () => {

    return instance
      .post('/login', credentials)
      .then((response) => {
        // Store the access token in the component's state
        this.setState({ accessToken: response.data.access_token });
        return response.data.access_token 
      })
      .catch((error) => {
        console.error(error);
      });
  };

  componentDidMount() {
    this.fetchAccessToken().then( (token) => {
      //console.log('Token: ', token);
    instance
      .get('/media/today/frontpages', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.setState({ frontpages: response.data });
        //console.log('data: ', response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    }
    );
  }
  

  handleMediaChange = (event) => {
    this.setState({ selectedMedia: event.target.value });
  };

  toggleDrawer = (open) => () => {
    this.setState({ isDrawerOpen: open });
  };

  render() {
    const { frontpages, selectedMedia, isDrawerOpen } = this.state;
    // Shuffle the frontpages array in random order
    const shuffledFrontpages = [...frontpages].sort(() => Math.random() - 0.5);

    return (
      <div className="frontpages-home">
        <ResponsiveAppBar toggleDrawer={this.toggleDrawer} />
        <Drawer anchor="left" open={isDrawerOpen} onClose={this.toggleDrawer(false)}>
          <List>
            <ListItem button onClick={this.toggleDrawer(false)}>
              <ListItemText primary="Todos" 
              onClick={() => this.setState({ selectedMedia: '', isDrawerOpen: false })} 
              />
            </ListItem>
            {frontpages.map((frontpage) => (
              <ListItem
                button
                key={frontpage.media_name}
                onClick={() => this.setState({ selectedMedia: frontpage.media_name, isDrawerOpen: false })}
              >
                <ListItemText primary={frontpage.media_name} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 10,
            pb: 6,
          }}
        >
          <Typography
        component="h1"
        variant="h3"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Portadas del día
      </Typography> 
          <Button variant="outlined" onClick={this.toggleDrawer(true)}>Filtrar por medio</Button>
          
        </Box>
        
        {frontpages.length > 0 ? (
          shuffledFrontpages.map((frontpage) => (
            <MediaGrid
              key={frontpage.media_name}
              frontpage={frontpage}
              selectedMedia={selectedMedia}
            />
          ))
        ) : (
          <LinearProgress />
        )}
      </div>
    );
  }
}

export default TodayFrontpages;
