import React, { Component } from 'react';
import ResponsiveAppBar from './ResponsiveAppBar';
import ArticleCard from './ArticleCard';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { Box,Grid} from '@mui/material';



// Create a new Axios instance with custom configuration
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const credentials = { username: process.env.REACT_APP_PORTADASCR_API_USER, password: process.env.REACT_APP_PORTADASCR_API_PASS};
  
  class LatestArticles extends Component {

    

    constructor(props) {
        super(props);
        this.state = {
          articles: [],
          accessToken: null,
          scrollPosition: 0
        };
      }

      fetchAccessToken = () => {

        return instance
          .post('/login', credentials)
          .then((response) => {
            // Store the access token in the component's state
            this.setState({ accessToken: response.data.access_token });
            return response.data.access_token 
          })
          .catch((error) => {
            console.error(error);
          });
      };

    // Function to save the scroll position
    saveScrollPosition = () => {
      this.setState({ scrollPosition: window.scrollY });
    };

    // Function to restore the scroll position
    restoreScrollPosition = () => {
      window.scrollTo(0, this.state.scrollPosition);
    };

    
    fetchLatestArticles = () => {
      this.fetchAccessToken().then( (token) => {
        //console.log('Token: ', token);
      instance
        .get('/media/articles/latest', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.setState({ articles: response.data });
          
          //console.log('data: ', response.data);
        })
        .catch((error) => {
          console.error('Error at fetching articles',error);
        });

      }
      );
       
      };

      componentDidMount() {

        this.fetchLatestArticles();
        
      }
    
      


    render() {
        const { articles } = this.state;
        
        // Sort the articles based on the datetime property in descending order
        const sortedArticles = [...articles].sort((a, b) => new Date(b.article.datetime) - new Date(a.article.datetime));
        
    
        return (
            <div className="media-frontpage">
            <ResponsiveAppBar toggleDrawer={this.toggleDrawer} />
            <Box sx={{ mt: 2}}>  
            <Typography
                component="h1"
                variant="h3"
                align="center"
                color="text.primary"
                gutterBottom
              >
                Últimos articulos
              </Typography>   
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Los 100 articulos más recientes 
            </Typography> 
            <Container key="latest-news" sx={{ py: 4, backgroundColor: '#F9F9F9', borderRadius: '10px' }} maxWidth="lg">
                <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" rowSpacing={4} spacing={4}>
                    {sortedArticles
                    .map((a) => (
                        <ArticleCard key={a.article.url} articles={a.article} media_name={a.media_name}/>
                    ))}
                    </Grid>
                    </Container>
            </Box>
            </div>
        );

  }
}   


  export default LatestArticles;