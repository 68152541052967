import React from 'react';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

class ArticleCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalScrollPosition: 0
    };
  }

  handleModalOpen = () => {
    this.setState({ isModalOpen: true, modalScrollPosition: window.scrollY });
  };

  handleModalClose = () => {
    window.scrollTo(0, this.state.modalScrollPosition);
    this.setState({ isModalOpen: false });
  };

  handleOpenInNewTab = () => {
    window.open(this.props.articles.url, '_blank');
  };

  handleCopyToClipboard = () => {
    const { articles } = this.props;
    navigator.clipboard.writeText(articles.url)
      .then(() => {
        console.log('URL copied to clipboard:', articles.url);
        // Add any additional logic or feedback here (e.g., displaying a success message)
      })
      .catch((error) => {
        console.error('Failed to copy URL to clipboard:', error);
        // Add any error handling or feedback here (e.g., displaying an error message)
      });
  };

  render() {
    const { articles } = this.props;
    const { media_name } = this.props ?? '';
    const { isModalOpen } = this.state;

    return (
      <Grid item key={articles.url} xs={12} sm={6} md={4} lg={4}>
        <div className="article">
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography gutterBottom variant="h6" component="h2" sx={{ '& a': { color: 'black', textDecoration: 'none' }, '&:hover': {backgroundColor: '#F0F0F0'} }}>
                <a href="#" onClick={this.handleModalOpen} >
                  {articles.title}
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                    }}
                    image={articles.image_url}
                  />
                </a>
              </Typography>
            
            <CardContent sx={{ flexGrow: 1 }}>
            {media_name ? (
            <Typography gutterBottom variant="h6" component="h2" sx={{ '& a': { color: 'black', textDecoration: 'none' } }}>
                  {media_name}
              </Typography>
            ) : null}
              <Typography variant="body-2" display="block">
                {articles.text}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">{articles.author}</Button>
              <Button size="small" variant="outlined">
                {moment.utc(articles.datetime).utcOffset('-00:00').format('DD/MM/YY - HH:mm')}
              </Button>
            </CardActions>
          </Card>
        </div>

        <Modal open={isModalOpen} onClose={this.handleModalClose}>
          <div style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ position: 'relative', width: '80%', height: '80%', backgroundColor: '#FFF', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
              <Box sx={{ position: 'absolute', top: '1rem', right: '1rem', zIndex: 1 }}>
                <IconButton onClick={this.handleModalClose} sx={{ backgroundColor: '#FFF', width: '60px', height: '60px', boxShadow: '2px 4px 6px rgba(0, 0, 0, 0.2)','&:hover': {backgroundColor: '#F0F0F0'} }}>
                  <HighlightOffIcon sx={{ fontSize: '32px' }}/>
                </IconButton>
              </Box>
              <Box sx={{ position: 'absolute', top: '1rem', right: '5rem', zIndex: 1 }}>
                <IconButton onClick={this.handleOpenInNewTab} sx={{ backgroundColor: '#FFF',width: '60px', height: '60px', boxShadow: '2px 4px 6px rgba(0, 0, 0, 0.2)','&:hover': {backgroundColor: '#F0F0F0'} }}>
                  <OpenInBrowserIcon sx={{ fontSize: '32px' }}/>
                </IconButton>
              </Box>
              <Box sx={{ position: 'absolute', top: '1rem', right: '9rem', zIndex: 1 }}>
                <IconButton onClick={this.handleCopyToClipboard} sx={{ backgroundColor: '#FFF',width: '60px', height: '60px', boxShadow: '2px 4px 6px rgba(0, 0, 0, 0.2)','&:hover': {backgroundColor: '#F0F0F0'}}}>
                  <ContentCopyIcon sx={{ fontSize: '32px' }}/>
                </IconButton>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <iframe src={articles.url} title={articles.title} width="100%" height="100%" frameBorder="0" />
              </Box>
            </Box>
          </div>
        </Modal>
      </Grid>
    );
  }
}

export default ArticleCard;
