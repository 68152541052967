import React from 'react';
import ArticleCard from './ArticleCard';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';



class MediaGrid extends React.Component {

  render() {
    const { frontpage, selectedMedia } = this.props;
    const frontpages = Array.isArray(frontpage) ? frontpage : [frontpage];

    const filteredFrontpage = selectedMedia
      ? frontpages.filter((fp) => fp.media_name === selectedMedia)
      : frontpages;

    return (
      <div className="media-frontpage">
        {filteredFrontpage.map((fp) => (
            <Container key={fp.media_name} sx={{ py: 4, backgroundColor: '#F9F9F9', borderRadius: '10px' }} maxWidth="lg">
              <Typography gutterBottom variant="h4" component="h2" sx={{ '& a': { color: 'black', textDecoration: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }, '&:hover': {backgroundColor: '#F0F0F0'} }}>
                <a href= {fp.url} target="_blank" rel="noopener noreferrer">
                {fp.media_name}
                </a>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  component="p"
                >
                  {fp.url}
                </Typography>
              </Typography>
              <hr className="divider" />
            {frontpages.map((frontpage) => (
              <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" rowSpacing={4} spacing={4}>
                  {fp.articles
                  .sort((a, b) => new Date(b.datetime) - new Date(a.datetime)) // Sort articles by date in descending order
                  .map((article) => (
                    <ArticleCard key={article.url} articles={article} />
                  ))}
                  </Grid>
              ))}
            </Container>
            
        ))}
      </div>
    );
  }
}

export default MediaGrid;




